import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React from "react";
import { useConfigureDispositionCollapsibleContext } from "../ConfigureDispositionCollapsibleContext";
import "./configureDispositionCollapsibleHeader.scss";

interface IConfigureDispositionCollapsibleHeaderProps {
    title: React.ReactNode;
    selection: React.ReactNode;
}

export function ConfigureDispositionCollapsibleHeader(props: IConfigureDispositionCollapsibleHeaderProps) {
    const { open, setOpen } = useConfigureDispositionCollapsibleContext();

    return (
        <div
            className={clsx("configure-disposition-collapsible-header", {
                "configure-disposition-collapsible-header--open": open,
            })}
            onClick={() => {
                setOpen(!open);
            }}>
            <h4 className="configure-disposition-collapsible-header__title">{props.title}</h4>

            <span className="configure-disposition-collapsible-header__selection">{props.selection}</span>
            <FontAwesomeIcon icon={faChevronRight} className="configure-disposition-collapsible-header__icon" />
        </div>
    );
}
