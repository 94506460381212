import { createContext, useContext } from "react";

interface IConfigureDispositionCollapsibleContextProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export const ConfigureDispositionCollapsibleContext =
    createContext<IConfigureDispositionCollapsibleContextProps | null>(null);

export function useConfigureDispositionCollapsibleContext() {
    const context = useContext(ConfigureDispositionCollapsibleContext);
    if (!context) {
        throw new Error(
            "useConfigureDispositionCollapsibleContext must be used inside a <ConfigureDispositionCollapsibleContext.Provider>"
        );
    }

    return context;
}
