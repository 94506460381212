import React from "react";
import { ConfigureDispositionCollapsibleContext } from "../ConfigureDispositionCollapsibleContext";

interface IConfigureDispositionCollapsibleRootProps {
    children: React.ReactNode;

    open: boolean;
    onOpenChange: (open: boolean) => void;
}

export function ConfigureDispositionCollapsibleRoot(props: IConfigureDispositionCollapsibleRootProps) {
    return (
        <ConfigureDispositionCollapsibleContext.Provider value={{ open: props.open, setOpen: props.onOpenChange }}>
            <div className="configure-disposition-collapsible-root">{props.children}</div>
        </ConfigureDispositionCollapsibleContext.Provider>
    );
}
