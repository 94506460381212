import { IonButton } from "@ionic/react";
import clsx from "clsx";
import React from "react";
import useResizeObserver from "use-resize-observer";
import { useConfigureDispositionCollapsibleContext } from "../ConfigureDispositionCollapsibleContext";
import "./configureDispositionCollapsibleList.scss";

interface IConfigureDispositionCollapsibleListProps {
    children: React.ReactNode;

    onSelectAll: () => void;
    onDeselectAll: () => void;
}

export function ConfigureDispositionCollapsibleList(props: IConfigureDispositionCollapsibleListProps) {
    const { open } = useConfigureDispositionCollapsibleContext();

    const { ref, height = 0 } = useResizeObserver({
        box: "border-box",
    });

    const animatedStyles: React.CSSProperties = {
        paddingTop: PADDING_TOP,
        height: open ? `calc(${height}px + ${PADDING_TOP})` : 0,
    };

    return (
        <div
            className={clsx("configure-disposition-collapsible-list", {
                "configure-disposition-collapsible-list--open": open,
            })}
            style={animatedStyles}>
            <div ref={ref} className="configure-disposition-collapsible-list__inner">
                <div className="configure-disposition-collapsible-list__actions">
                    <IonButton color="primary" size="small" onClick={props.onSelectAll}>
                        Alle auswählen
                    </IonButton>
                    <IonButton color="danger" size="small" onClick={props.onDeselectAll}>
                        Alle abwählen
                    </IonButton>
                </div>
                <div className="configure-disposition-collapsible-list__grid">{props.children}</div>
            </div>
        </div>
    );
}

const PADDING_TOP = "0.5rem";
